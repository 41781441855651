section {
  background-color: black;
  min-height: 100vh;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background-color: white;
  border-radius: 50%;
}

.rocket {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 500px;
}
